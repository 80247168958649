import(/* webpackMode: "eager" */ "/vercel/path0/apps/app/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["BaselimeRum"] */ "/vercel/path0/node_modules/@baselime/react-rum/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/[params]/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/packages/ui/components/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsContextProvider"] */ "/vercel/path0/packages/ui/context/AnalyticsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/packages/ui/context/NextThemesContext.tsx");

'use client'

import { BaselimeErrorBoundary } from '@baselime/react-rum'

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <BaselimeErrorBoundary
      fallbackRender={(error) => <div>{error.error.message}</div>}
    >
      {children}
    </BaselimeErrorBoundary>
  )
}
